.note-editor {
  width: 264px;
  height: 172px;
}

.editor-box-parent {
  position: absolute;
  left: 10px;
  top: 12px;
  width: 237px;
  height: 100px;
}
.editor-box {
  border: none;
  outline: none;
  resize: none;
  font-size: 15px;
  width: 100%;
  height: 70%;
  line-height: 18px;
  overflow-y: scroll;
}

.editor-box::placeholder {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.6;
  position: relative;
  top: 6px;
  font-weight: 500;
}
.editor-box::-ms-input-placeholder {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.6;
  position: relative;
  top: 6px;
  font-weight: 500;
}
.editor-box::-moz-placeholder {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.6;
  position: relative;
  top: 6px;
  font-weight: 500;
}
.note-button-container {
  position: absolute;
  bottom: 10px;
  right: 25px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.confirm-button {
  font-size: 15px;
  line-height: 15px;
  opacity: 1;
  cursor: pointer;
}
.cancel-button {
  font-size: 15px;
  line-height: 15px;
  opacity: 1;
  cursor: pointer;
  margin-right: 20px;
}
.note-tags {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
