.import-from-local {
  position: fixed;
  right: 160px;
  top: 0px;
  width: 138px;
  margin-right: 20px;
  margin-top: 23px;
  min-width: 42px;
  height: 42px;
  opacity: 1;
  border-radius: 25px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  z-index: 0;
  font-size: 15px;
  transition: 0.1s;
  font-weight: 500;
}

.import-book-box {
  position: relative;
  bottom: 67px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 500;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
}
.drag-section {
  position: absolute;
  top: 75px;
  width: calc(100% - 200px);
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
