.loading-dialog {
  width: 454px;
  height: 278px;
  position: absolute;
  left: calc(50% - 225px);
  top: calc(50% - 150px);
  overflow: hidden;
  opacity: 1;
  z-index: 20;
  border-radius: 5px;
  animation: popup 0.1s ease-in-out 0s 1;
}
.loading-dialog-title {
  width: 120px;
  height: 20px;
  font-size: 18px;
  opacity: 1;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-weight: 500;
}
