.image-preview {
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.image {
  z-index: 5;
}
.image-operation {
  position: fixed;
  left: calc(50% - 160px);
  bottom: 30px;
  font-size: 25px;
  z-index: 6;
}
.zoom-in-icon,
.zoom-out-icon,
.save-icon,
.clockwise-icon,
.counterclockwise-icon {
  margin: 20px;
  cursor: pointer;
}
.image-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}
