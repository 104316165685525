.book-list-view {
  position: absolute;
  right: -28px;
  top: 72px;
  font-size: 14px;
  margin-right: 30px;
  clear: both;
  width: 110px;
  z-index: 1;
  height: 20px;
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.1s ease;
}
.view-name {
  display: inline-block;
  width: 60px;
  text-align: center;
}
.card-list-mode {
  width: 20px;
  height: 20px;
  line-height: 17px;
  cursor: pointer;
  padding: 5px;
  padding-right: 10px;
  display: inline-block;
  font-size: 17px;
}

.book-list-item-box {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.icon-grid,
.icon-list {
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.view-mode-down {
  position: absolute;
  top: 15px;
  right: 15px;
}
