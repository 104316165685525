.tag-list-item {
  min-width: 45px;
  height: 20px;
  opacity: 1;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
}

.tag-title {
  font-size: 15px;
  line-height: 31px;
  opacity: 1;
  margin-left: 20px;
  font-weight: 500;
  width: 90px;
}
.tag-container {
  display: inline-block;
  margin: 0px 10px;
}
.tag-dropdown-icon {
  float: right;
  margin-left: 10px;
  position: relative;
  top: 2px;
  line-height: 30px;
  cursor: pointer;
  transition: transform 0.1s ease;
}
#newTag {
  width: 100%;
  border: none;
  border-radius: 10px;
  height: 100%;
  padding-left: 10px;
}
#newTag:focus {
  outline: none;
}
.tag-list-item-new {
  width: 20px;
  height: 20px;
  opacity: 1;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  font-size: 10px;
  margin-top: 5px;
  position: relative;
  bottom: 1px;
  box-sizing: border-box;
}
.icon-add {
  font-weight: bold;
}
.delete-tag-container {
  position: relative;
  left: 25px;
}
