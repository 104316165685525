.book-content-list {
  float: left;
  width: calc(100% - 15px);
  margin-left: 18px;
}
.book-content-name {
  display: block;
  padding: 10px 0px;
  width: 90%;
  font-size: 15px;
  line-height: 18px;
  opacity: 1;
  float: left;
  transition: none;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden; */
}
.book-subcontent-name {
  display: block;
  width: 220px;
  float: left;
  font-size: 13px;
  line-height: 15px;
  opacity: 0.64;
  padding: 8px;
}
.book-subcontent-list {
  margin-left: 18px;
}
.content-dropdown {
  float: right;
  margin-top: 15px;
  position: relative;
  right: 15px;
  opacity: 0.5;
  font-size: 13px;
  cursor: pointer;
  transition: transform 0.1s ease;
}
